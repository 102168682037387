import { Clear, Search } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { DatePicker, Grid, IconButton, TextField } from 'src/components/mui-components';
import { getLanguageFromSiteLocale, localeDateFormat } from 'src/utils/date';
import { IAssignDialogSearchForm } from './AssignDialogSearch.types';

interface IAssignDialogSearch {
  form: IAssignDialogSearchForm;
  setForm: (f: IAssignDialogSearchForm) => void;
  customLabels?: Partial<{
    endDate: string;
    search: string;
    startDate: string;
  }>;
}

export const AssignDialogSearch = ({ customLabels, form, setForm }: IAssignDialogSearch) => {
  const { t } = useTranslation('assignFlow');
  const siteLocale = useGetLocale();
  const siteLanguage = getLanguageFromSiteLocale(siteLocale);

  const updateForm = (f: Partial<IAssignDialogSearchForm>) => {
    setForm({ ...form, ...f });
  };

  return (
    <Grid
      container
      columnSpacing={5.75}
      data-automation-id="AssignDialogSearch"
      mt={0}
      rowSpacing={2}
    >
      <Grid item md={6} xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              ariaLabel={customLabels?.search ?? t('AssignDialogForm.SearchLabel')}
              autoFocus
              data-automation-id="SearchForEmployeeInput"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {form.query && (
                      <IconButton
                        title={t('ClearSearchButtonText')}
                        onClick={() => updateForm({ query: '' })}
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    )}
                    <Search fontSize="small" />
                  </InputAdornment>
                ),
              }}
              label={customLabels?.search ?? t('AssignDialogForm.SearchLabel')}
              onChange={(e) => updateForm({ query: e.target.value })}
              size="small"
              value={form.query}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container columnSpacing={1}>
              <Grid item xs={6}>
                <DatePicker
                  aria-label={customLabels?.startDate ?? t('AssignDialogForm.StartDateLabel')}
                  data-automation-id="StartDatePicker"
                  format={localeDateFormat(siteLanguage)}
                  label={customLabels?.startDate ?? t('AssignDialogForm.StartDateLabel')}
                  minDate={new Date()}
                  onChange={(value) => updateForm({ startDate: value })}
                  slotProps={{
                    textField: { size: 'small', id: 'start-date-picker', sx: { width: '100%' } },
                  }}
                  value={form.startDate}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  aria-label={customLabels?.endDate ?? t('AssignDialogForm.EndDateLabel')}
                  data-automation-id="EndDatePicker"
                  format={localeDateFormat(siteLanguage)}
                  label={customLabels?.endDate ?? t('AssignDialogForm.EndDateLabel')}
                  minDate={form.startDate ?? new Date()}
                  onChange={(value) => updateForm({ endDate: value })}
                  slotProps={{
                    textField: { size: 'small', id: 'end-date-picker', sx: { width: '100%' } },
                  }}
                  value={form.endDate}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
