import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postFetch } from 'src/utils/fetcher';
import { formatUrl } from 'src/utils/url';
import { GROUP_BY_PROJECT_KEY } from '../get/getGroupByProjectAPI.constants';
import { GROUP_BY_RESOURCE_KEY } from '../get/getGroupByResourceAPI';

const POST_IMPORT_PLANS_URL_PATH = '/api/v2/resource-planner/add-resource-plans';
export const POST_IMPORT_PLANS_MSW_STATUS_KEY = 'PostImportResourcePlans';

export default () => {
  const path = formatUrl(POST_IMPORT_PLANS_URL_PATH);
  const importResourcePlans = () =>
    postFetch({
      path,
      key: POST_IMPORT_PLANS_MSW_STATUS_KEY,
    });
  const qc = useQueryClient();

  return useMutation(importResourcePlans, {
    onSuccess: () => {
      qc.invalidateQueries([GROUP_BY_RESOURCE_KEY]);
      qc.invalidateQueries([GROUP_BY_PROJECT_KEY]);
    },
  });
};
