import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TCheckedRadioButtonSelection } from 'src/screens/ResourcePlanner/components/DistributeUnplannedWorkloadModalContent/DistributeUnplannedWorkloadModalContent';
import { postFetch } from 'src/utils/fetcher';
import { formatUrl } from 'src/utils/url';
import { GROUP_BY_PROJECT_KEY } from '../get/getGroupByProjectAPI.constants';
import { GROUP_BY_RESOURCE_KEY } from '../get/getGroupByResourceAPI';

const POST_DISTRIBUTE_UNPLANNED_HOURS_URL_PATH = '/api/v2/resource-planner/smart-plan';

export const POST_DISTRIBUTE_UNPLANNED_HOURS_MSW_STATUS_KEY = 'SmartPlan';

interface IPostDistributeUnplannedHours {
  resourceId?: string;
  workItemId?: string;
  startsAt?: string;
  endsAt?: string;
  selectionType: TCheckedRadioButtonSelection;
}

export default () => {
  const path = formatUrl(POST_DISTRIBUTE_UNPLANNED_HOURS_URL_PATH);

  const postDistributeUnplannedHours = ({
    resourceId,
    workItemId,
    startsAt,
    endsAt,
    selectionType,
  }: IPostDistributeUnplannedHours) =>
    postFetch({
      path,
      key: POST_DISTRIBUTE_UNPLANNED_HOURS_MSW_STATUS_KEY,
      body: JSON.stringify({
        ResourceId: resourceId,
        WorkItemId:
          typeof workItemId === 'string' && workItemId.includes('_')
            ? workItemId.split('_')[1]
            : workItemId,
        StartsAt: startsAt,
        EndsAt: endsAt,
        SelectionType: selectionType,
      }),
    });
  const qc = useQueryClient();

  return useMutation(postDistributeUnplannedHours, {
    onSuccess: () => {
      qc.invalidateQueries([GROUP_BY_RESOURCE_KEY]);
      qc.invalidateQueries([GROUP_BY_PROJECT_KEY]);
    },
  });
};
