export default {
  Properties: {
    UserId: 1,
    FullName: 'FirstName_Admin1 LastName',
    Position: '',
    LegalEntity: 'NPO',
    Department: 'Produktion',
    Capacity: {
      AvailableHours: 0,
      TotalHours: 0,
    },
    Competences: [
      {
        Id: 1,
        Name: 'Dansk',
        Value: 5,
      },
      {
        Id: 4,
        Name: 'Økonomisk styring',
        Value: 5,
      },
      {
        Id: 14,
        Name: 'test',
        Value: 4,
      },
    ],
  },
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/resource-planner/user/1/capacity?periodStartDate=2024-03-07&periodEndDate=2024-04-06',
      Rel: 'self',
    },
  ],
};
