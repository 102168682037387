import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteFetch } from 'src/utils/fetcher';
import { formatUrl } from 'src/utils/url';
import { GROUP_BY_PROJECT_KEY } from '../../get/getGroupByProjectAPI.constants';
import { GROUP_BY_RESOURCE_KEY } from '../../get/getGroupByResourceAPI';
import { GROUP_BY_RESOURCE_TOTAL_KEY } from '../../get/getGroupByResourceTotalAPI';
import { TDeleteWorkItemRelationshipReq } from './deleteWorkItemRelationshipAPI.types';

const DELETE_WORK_ITEM_RELATIONSHIP_URL_PATH = '/api/v2/resource-planner/work-item-relationship';
const DELETE_WORK_ITEM_RELATIONSHIP_KEY = 'deleteRelationship';

const deleteWorkItemRelationship = ({
  workItemId,
  workItemSourceReferenceId,
  workItemType,
  resourceId,
  resourceSourceReferenceId,
  resourceType,
}: TDeleteWorkItemRelationshipReq) =>
  deleteFetch({
    path: formatUrl(DELETE_WORK_ITEM_RELATIONSHIP_URL_PATH),
    key: DELETE_WORK_ITEM_RELATIONSHIP_KEY,
    body: JSON.stringify({
      workItemId,
      workItemSourceReferenceId,
      workItemType,
      resourceId,
      resourceSourceReferenceId,
      resourceType,
    }),
  });

export const useDeleteWorkItemRelationship = (onSuccess?: () => void) => {
  const qc = useQueryClient();

  return useMutation(deleteWorkItemRelationship, {
    onSuccess: () => {
      qc.invalidateQueries([GROUP_BY_RESOURCE_KEY]);
      qc.invalidateQueries([GROUP_BY_RESOURCE_TOTAL_KEY]);
      qc.invalidateQueries([GROUP_BY_PROJECT_KEY]);
      onSuccess?.();
    },
  });
};
