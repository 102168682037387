import CompetenceDropDownFullResult from './competenceDropDownFullResult';
import DepartmentDropDownFullResult from './departmentDropDownFullResult';
import EmployeeDropDownFullResult from './employeeDropDownFullResult';
import ProjectDropDownFullResult from './projectDropDownFullResult';
import ProjectManagerDropDownFullResult from './projectManagerDropDownFullResult';

export default {
  competence: CompetenceDropDownFullResult,
  department: DepartmentDropDownFullResult,
  employee: EmployeeDropDownFullResult,
  project: ProjectDropDownFullResult,
  'project-manager': ProjectManagerDropDownFullResult,
} as Record<string, object>;
