import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { getFetch } from 'src/utils/fetcher';
import { getPathWithQueries } from 'src/utils/url/url';
import { TCapacityRes } from './getCapacityAPI.types';

const GET_CAPACITY_URL_PATH = '/api/v2/resource-planner/user/{userId}/capacity';
const GET_CAPACITY_KEY = 'Capacity';

interface IGetCapacity {
  periodStartDate: Date;
  periodEndDate: Date;
  userId: string;
}

const getCapacity = ({
  periodStartDate,
  periodEndDate,
  userId,
}: IGetCapacity): Promise<TCapacityRes> =>
  getFetch({
    path: getPathWithQueries(GET_CAPACITY_URL_PATH.replace('{userId}', userId), {
      periodStartDate: format(periodStartDate, 'yyyy-MM-dd'),
      periodEndDate: format(periodEndDate, 'yyyy-MM-dd'),
    }),
    key: GET_CAPACITY_KEY,
  });

export const useGetCapacity = (params: IGetCapacity, enable?: boolean) => {
  const { data: { properties } = {}, ...rest } = useQuery(
    [GET_CAPACITY_KEY, params.periodStartDate, params.periodEndDate, params.userId],
    () => getCapacity(params),
    {
      enabled: enable && params.periodStartDate.toDateString() >= new Date().toDateString(),
    },
  );

  return { data: properties, ...rest };
};
