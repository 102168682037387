import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { DatePicker } from 'src/components/mui-components';
import { useCustomEditComponent } from 'src/screens/ResourcePlanner/hooks';
import { getLanguageFromSiteLocale, localeDateFormat } from 'src/utils/date';
import { ComponentWrapper } from '../EditableComponents';
import { EColumnName, TDate } from '../EditableComponents.types';

interface IEditableEndDate extends GridRenderEditCellParams {
  ariaLabel: string;
  colName: EColumnName.ALLOCATION_START_DATE | EColumnName.START_DATE;
  dataAutomationId: string;
  maxDate: TDate;
  textFieldId: string;
}

export const EditableEndDate = ({
  ariaLabel,
  colName,
  dataAutomationId,
  field,
  id,
  maxDate,
  row,
  textFieldId,
  value,
}: IEditableEndDate) => {
  const apiRef = useGridApiContext();
  const siteLocale = useGetLocale();
  const siteLanguage = getLanguageFromSiteLocale(siteLocale);
  const { handleChange } = useCustomEditComponent<TDate>({ field, id });

  const column = apiRef.current.getColumn(colName);
  const startDate = apiRef.current.getRowValue(row, column);

  return (
    <ComponentWrapper>
      <DatePicker
        data-automation-id={`${id}${dataAutomationId}`}
        format={localeDateFormat(siteLanguage)}
        maxDate={maxDate ?? undefined}
        minDate={startDate ?? new Date()}
        onChange={handleChange}
        value={value}
        slotProps={{
          textField: { size: 'small', id: textFieldId, inputProps: { 'aria-label': ariaLabel } },
        }}
      />
    </ComponentWrapper>
  );
};
