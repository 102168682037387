import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { getPathWithQueries } from 'src/utils/url/url';
import { TUnallocatedTasksRes } from './getUnallocatedTasksAPI.types';

const GET_UNALLOCATED_TASKS_URL_PATH = '/api/v2/projects/{projectId}/tasks-unallocated';
const GET_UNALLOCATED_TASKS_KEY = 'UnallocatedTasks';

interface IGetUnallocatedTasks {
  projectId?: string;
  userId?: string;
}

const getUnallocatedTasks = ({
  projectId = '',
  userId,
}: IGetUnallocatedTasks): Promise<TUnallocatedTasksRes> =>
  getFetch({
    path: getPathWithQueries(GET_UNALLOCATED_TASKS_URL_PATH.replace('{projectId}', projectId), {
      userId,
    }),
    key: GET_UNALLOCATED_TASKS_KEY,
  });

export const useGetUnallocatedTasks = (params: IGetUnallocatedTasks) => {
  const { data: { entities } = {}, ...rest } = useQuery(
    [GET_UNALLOCATED_TASKS_KEY, params.projectId, params.userId],
    () => getUnallocatedTasks(params),
    {
      enabled: !!params.projectId && !!params.userId,
    },
  );

  return { data: entities, ...rest };
};
