import cx from 'classnames';
import { Box, Tooltip, Typography } from 'src/components/mui-components';
import useAutoId from 'src/hooks/useAutoId';
import { getAvailabilityColor } from 'src/screens/ResourcePlanner/helper/getAvailabilityColor';
import { roundNumberToTwoDigits as round } from 'src/utils/number';
import { useDebounce } from 'use-debounce';
import styles from './HoursBar.module.scss';

interface IHoursBar {
  addedHours: number;
  availableHours: number;
  userId: string;
  totalHours: number;
  tooltip?: Partial<{
    default: string;
    exceededAvailableHours: string;
  }>;
}

export const HoursBar = ({
  addedHours = 0,
  availableHours,
  userId,
  totalHours,
  tooltip,
}: IHoursBar) => {
  const autoId = useAutoId();

  const utilizedHours = round(totalHours - availableHours);
  const utilizedProgress = totalHours === 0 ? 0 : round(utilizedHours / totalHours);
  const [debouncedAddedHours] = useDebounce(addedHours, 500);
  const addedProgress = totalHours === 0 ? 1 : round(debouncedAddedHours / totalHours);
  const exceededAvailableHours =
    debouncedAddedHours && round(availableHours - debouncedAddedHours) < 0;
  const tooltipTitle = exceededAvailableHours ? tooltip?.exceededAvailableHours : tooltip?.default;

  return (
    <Tooltip followCursor title={tooltipTitle}>
      <Box
        aria-label={tooltip?.default}
        className={styles.progressBarBg}
        data-automation-id={`HoursBar-${userId}`}
        id={`HoursBar-${autoId}`}
      >
        <Box
          className={cx(styles.filled, utilizedProgress ? styles.hasProgress : '')}
          sx={{
            borderColor: getAvailabilityColor(utilizedProgress),
            width: `calc(${utilizedProgress * 100}% + 8px)`,
          }}
        />
        <Box
          className={cx(styles.added, debouncedAddedHours ? styles.hasAdded : '')}
          sx={{ width: `calc(${addedProgress * 100}% + 8px)` }}
        />
        <Box className={cx(styles.warningBorder, exceededAvailableHours ? styles.visible : '')} />
        <Typography className={styles.hours}>
          {round(availableHours - debouncedAddedHours)} / {round(totalHours)} h
        </Typography>
      </Box>
    </Tooltip>
  );
};
