import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

/**
 * Hook to get window size on window resize.
 *
 * Debounced by 500ms for better performance.
 */
export const useGetWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [debouncedWindowSize] = useDebounce(windowSize, 500);

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { ...debouncedWindowSize };
};
