export default {
  Entities: [
    {
      Properties: {
        UserId: 50,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        UserId: 70,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        UserId: 71,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        UserId: 54,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        UserId: 1,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        UserId: 72,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        UserId: 6,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        UserId: 9,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        UserId: 12,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        UserId: 11,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        UserId: 14,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        UserId: 37,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        UserId: 53,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        UserId: 13,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        UserId: 75,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        UserId: 51,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        UserId: 65,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
  ],
  Links: [
    {
      Href: 'https://uat.timelog.com/rp-test1/api/v2/projects/108/employee-unallocated?taskId=928',
      Rel: 'self',
    },
  ],
};
