import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  GridRowModes,
  GridRowModesModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IEmployeeSearchResProperties,
  IHourlyRatesResProperties,
} from 'src/apis/resourcePlannerAPI';
import { Box, DataGrid } from 'src/components/mui-components';
import { useResourcePlannerStore } from 'src/stores/ResourcePlannerStore/ResourcePlannerStore';
import { CompetenceList } from '../CompetenceList';
import {
  EColumnName,
  EditableEndDate,
  EditableHourlyRate,
  EditableHours,
  EditableStartDate,
} from '../EditableComponents';
import { EmployeeCard } from '../EmployeeCard';
import { NoRowsOverlay } from '../EmptyStates/NoRowsOverlay';
import { EEmployeeTableField, TEmployee, TEmployeeTableRow } from './EmployeeTable.types';

type TEmployeeTableHandle = {
  getFormIsValid: () => boolean;
  getFormValues: () => TEmployeeTableRow[];
};

interface IEmployeeTable {
  data: TEmployee[];
  endDate: Date | null;
  hourlyRates: IHourlyRatesResProperties[];
  isFetching: boolean;
  startDate: Date | null;
  query?: string;
}

export const EmployeeTable = forwardRef<TEmployeeTableHandle, IEmployeeTable>(
  ({ data, endDate, hourlyRates, isFetching, query, startDate }, ref) => {
    const { t } = useTranslation('assignFlow');
    const apiRef = useGridApiRef();

    const { dateEnd } = useResourcePlannerStore();

    const [employeeList, setEmployeeList] = useState<TEmployeeTableRow[]>([]);
    const employeeCurrency = employeeList?.[0]?.hourlyRateCurrencyAbb;
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>();

    const colDef = useRef<Partial<GridColDef>>({
      sortable: false,
    });

    const columns: GridColDef[] = useMemo(
      () => [
        {
          ...colDef.current,
          field: EEmployeeTableField.fullName,
          flex: 2,
          headerName: t('EmployeeTable.Employee'),
          minWidth: 400,
          renderCell: ({
            id,
            row: { capacity, department, fullName, legalEntity, position, userId },
          }: GridRenderCellParams<TEmployeeTableRow>) => (
            <Box flex={1} sx={{ marginBlock: 1.5 }}>
              <EmployeeCard
                addedHours={apiRef.current.getCellValue(id, 'hoursInt')}
                capacity={capacity}
                department={department}
                fullName={fullName}
                key={id}
                legalEntity={legalEntity}
                position={position}
                query={query}
                userId={userId}
              />
            </Box>
          ),
        },
        {
          ...colDef.current,
          field: EEmployeeTableField.competences,
          flex: 1,
          headerName: t('EmployeeTable.Competences'),
          isFetching,
          minWidth: 250,
          renderCell: ({
            row: { competences },
          }: GridRenderCellParams<IEmployeeSearchResProperties>) => (
            <CompetenceList competenceList={competences} />
          ),
        },
        {
          ...colDef.current,
          editable: true,
          field: EEmployeeTableField.startDate,
          headerName: t('EmployeeTable.StartDate'),
          renderEditCell: (params) => (
            <EditableStartDate
              {...params}
              ariaLabel={t('EditableComponents.StartDateLabel', { name: params.row.name })}
              dataAutomationId="StartDate"
              maxDate={endDate}
              minDate={startDate}
              textFieldId="StartDatePicker"
            />
          ),
          type: 'date',
          width: 160,
        },
        {
          ...colDef.current,
          editable: true,
          field: EEmployeeTableField.endDate,
          headerName: t('EmployeeTable.EndDate'),
          renderEditCell: (params) => (
            <EditableEndDate
              {...params}
              ariaLabel={t('EditableComponents.EndDateLabel', { name: params.row.name })}
              colName={EColumnName.START_DATE}
              dataAutomationId="EndDate"
              maxDate={endDate}
              textFieldId="EndDatePicker"
            />
          ),
          type: 'date',
          width: 160,
        },
        {
          ...colDef.current,
          editable: true,
          field: EEmployeeTableField.hours,
          headerAlign: 'right',
          headerName: t('EmployeeTable.AllocatedHours'),
          renderEditCell: (params) => <EditableHours {...params} />,
          width: 126,
        },
        {
          ...colDef.current,
          editable: true,
          field: EEmployeeTableField.hourlyRate,
          headerName: t('EmployeeTable.HourlyRate', { currency: employeeCurrency ?? 'DKK' }),
          renderEditCell: (params) => (
            <EditableHourlyRate {...params} hourlyRates={hourlyRates} isFetching={isFetching} />
          ),
          type: 'singleSelect',
          width: 152,
        },
      ],
      [apiRef, employeeCurrency, endDate, hourlyRates, isFetching, query, startDate, t],
    );

    const getSelectedRows = () => {
      if (!apiRef.current?.getSelectedRows?.()?.size) {
        return [];
      }

      const selectedRowIds = Array.from(apiRef.current.getSelectedRows().keys());

      return selectedRowIds.reduce(
        (a, b) => [...a, apiRef.current.getRowWithUpdatedValues(b, '') as TEmployeeTableRow],
        [] as TEmployeeTableRow[],
      );
    };

    const getFormIsValid = () => {
      const selectedRowIds = Array.from(apiRef.current.getSelectedRows().keys());

      return selectedRowIds.reduce((a, b) => {
        if (apiRef.current.getCellValue(b, EEmployeeTableField.error)) {
          return false;
        }

        return a;
      }, true);
    };

    useImperativeHandle(ref, () => ({
      getFormIsValid,
      getFormValues: getSelectedRows,
    }));

    useEffect(() => {
      setEmployeeList(
        data.map((e) => ({
          ...e,
          endDate: dateEnd,
          hourlyRate: '',
          hours: '',
          id: e.userId,
          startDate: new Date(),
        })),
      );
    }, [data, dateEnd, endDate, startDate]);

    useEffect(() => {
      const allRows = apiRef.current.getAllRowIds();

      if (!allRows.length) {
        return;
      }

      allRows.forEach((r) => {
        apiRef.current.setEditCellValue({ id: r, field: 'endDate', value: endDate });
        apiRef.current.setEditCellValue({ id: r, field: 'startDate', value: startDate });
      });
    }, [apiRef, endDate, startDate]);

    useEffect(() => {
      if (!employeeList.length) {
        return;
      }

      const rowModes = employeeList.reduce(
        (a, b) => ({
          ...a,
          [b.userId]: { mode: GridRowModes.Edit },
        }),
        {},
      );

      setRowModesModel(rowModes);
    }, [employeeList]);

    return (
      <DataGrid
        apiRef={apiRef}
        checkboxSelection
        columns={columns}
        disableColumnMenu
        disableCellOutline
        disableColumnReorder
        disableColumnResize
        disableColumnTopBorder
        disableRowSelectionOnClick
        disableVirtualization
        editMode="row"
        getRowHeight={() => 'auto'}
        hideFooter
        initialState={{
          pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, EEmployeeTableField.fullName],
          },
        }}
        loading={isFetching}
        rowModesModel={rowModesModel}
        rows={employeeList}
        slots={{
          noRowsOverlay: () => NoRowsOverlay({ message: t('EmployeeTable.NoResults') }),
        }}
        sx={{
          '.MuiDataGrid-row--editing': {
            boxShadow: 'none',
            '.MuiDataGrid-cell': {
              backgroundColor: 'transparent',
            },
          },
        }}
      />
    );
  },
);
