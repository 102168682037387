import { InputAdornment, MenuItem } from '@mui/material';
import { CircularProgress, Select, Typography } from 'src/components/mui-components';
import { ComponentWrapper } from '../EditableComponents';
import styles from '../EditableComponents.module.scss';
import { THourlyRateDropdown } from '../EditableComponents.types';

export const HourlyRateDropdown = ({
  hourlyRates,
  id,
  isFetching,
  isShownHourlyRateDropDown,
  onChange,
  onClose,
  onMouseOver,
  onOpen,
  open,
  suggestedHourlyRateAmount,
  suggestedHourlyRateId,
  suggestedHourlyRateName,
  value,
  ...rest
}: THourlyRateDropdown) => {
  const defaultLabel = `${suggestedHourlyRateAmount} ${suggestedHourlyRateName}`;

  if (!isShownHourlyRateDropDown) {
    return (
      <ComponentWrapper>
        <Typography>{defaultLabel}</Typography>
      </ComponentWrapper>
    );
  }

  return (
    <ComponentWrapper>
      <Select
        data-automation-id={`HourlyRate-${id}-Select`}
        endAdornment={
          isFetching && (
            <InputAdornment position="end" className={styles.editableSelectEndAdornment}>
              <CircularProgress size={20} />
            </InputAdornment>
          )
        }
        fullWidth
        id={String(id)}
        onChange={onChange}
        onClose={onClose}
        onMouseOver={onMouseOver}
        onOpen={onOpen}
        open={open}
        size="small"
        value={value ?? suggestedHourlyRateId}
        inputProps={{
          'aria-label': rest['aria-label'],
        }}
      >
        {hourlyRates?.length ? (
          hourlyRates.map((h) => (
            <MenuItem
              disabled={isFetching}
              key={h.contractHourlyRateID}
              value={h.contractHourlyRateID}
            >
              {h.amount} {h.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled={isFetching} key={suggestedHourlyRateId} value={suggestedHourlyRateId}>
            {defaultLabel}
          </MenuItem>
        )}
      </Select>
    </ComponentWrapper>
  );
};
