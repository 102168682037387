import { IAutocompleteWithRadiosOption, IFilter } from './components/AutocompleteWithRadios';
import { ICompetencePickerOption } from './components/CompetencePicker';

export interface IAssignDialogFormForm {
  endDate: Date | null;
  hours: string;
  query: string;
  startDate: Date | null;
}

export interface IAssignDialogFormFilters {
  competence: ICompetencePickerOption[];
  department: IAutocompleteWithRadiosOption[];
  legalEntity: IFilter;
  manager: IFilter;
}

export const ASSIGN_DIALOG_FORM: IAssignDialogFormForm = {
  endDate: null,
  hours: '',
  query: '',
  startDate: new Date(),
};

export const ASSIGN_DIALOG_FILTERS: IAssignDialogFormFilters = {
  competence: [],
  department: [],
  legalEntity: {
    isInclude: true,
    values: [],
  },
  manager: {
    isInclude: true,
    values: [],
  },
};
