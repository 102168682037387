import { AccountCircle } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from 'src/components/mui-components';
import { HighlightMatchingText } from 'src/components/utils/HighlightMatchingText';
import { getUserAvatar } from 'src/utils/getUserAvatar';
import { TEmployeeTableRow } from '../EmployeeTable';
import { HoursBar } from '../HoursBar';

type TEmployeeCard = Pick<
  TEmployeeTableRow,
  'capacity' | 'department' | 'legalEntity' | 'fullName' | 'position'
> & {
  addedHours?: number;
  query?: string;
  userId?: string;
};

export const EmployeeCard = ({
  addedHours = 0,
  capacity = {
    availableHours: 0,
    totalHours: 0,
  },
  department = '',
  legalEntity = '',
  fullName = '',
  position = '',
  query = '',
  userId = '',
}: TEmployeeCard) => {
  const { t } = useTranslation('assignFlow');

  const getText = (text: string) =>
    text ? (
      <Typography fontSize={12} lineHeight={1}>
        {text}
      </Typography>
    ) : null;

  const getSeparator = (condition1?: string, condition2?: string) =>
    condition1 && condition2 ? getText('·') : null;

  return (
    <Stack data-automation-id="EmployeeCard" direction="row" flex={1} gap={2}>
      <Stack justifyContent="center">
        {userId ? (
          <Avatar alt={fullName} src={getUserAvatar(userId)} sx={{ height: 32, width: 32 }} />
        ) : (
          <AccountCircle color="action" fontSize="large" />
        )}
      </Stack>
      <Stack flex={1}>
        <Typography lineHeight={1}>
          <HighlightMatchingText matchName={query} name={fullName} />
        </Typography>
        <Stack direction="row" flexWrap="wrap">
          {getText(position)}
          {getSeparator(position, department)}
          {getText(department)}
          {getSeparator(department, legalEntity)}
          {getText(legalEntity)}
        </Stack>
        <HoursBar
          addedHours={addedHours}
          availableHours={capacity.availableHours}
          userId={userId}
          totalHours={capacity.totalHours}
          tooltip={{
            default: t('EmployeeCard.TooltipDefault'),
            exceededAvailableHours: t('EmployeeCard.TooltipExceededHours'),
          }}
        />
      </Stack>
    </Stack>
  );
};
