import { Clear, FilterList, Search } from '@mui/icons-material';
import { Divider, InputAdornment } from '@mui/material';
import {
  SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from 'src/components/mui-components';
import { AssignDialogSearch } from '../AssignDialogSearch';
import styles from './AssignDialogForm.module.scss';
import { IAssignDialogFormFilters, IAssignDialogFormForm } from './AssignDialogForm.types';
import {
  AutocompleteWithRadios,
  IAutocompleteWithRadiosOption,
} from './components/AutocompleteWithRadios';
import { CompetencePicker } from './components/CompetencePicker';
import { FilterAutocomplete } from './components/FilterAutocomplete';

type TAssignDialogFormHandle = {
  getFilterValues: () => IAssignDialogFormFilters;
};

interface IAssignDialogForm {
  filters: IAssignDialogFormFilters;
  form: IAssignDialogFormForm;
  showDivider?: boolean;
  setForm: (f: SetStateAction<IAssignDialogFormForm>) => void;
  applyOnClick?: () => void;
  resetOnClick?: () => void;
}

export const AssignDialogForm = forwardRef<TAssignDialogFormHandle, IAssignDialogForm>(
  ({ applyOnClick, filters: parentFilters, form, resetOnClick, setForm, showDivider }, ref) => {
    const { t } = useTranslation('assignFlow');

    const [filters, setFilters] = useState<IAssignDialogFormFilters>(parentFilters);
    const [appliedFilters, setAppliedFilters] = useState<IAssignDialogFormFilters>();
    const [filtersIsExpanded, setFiltersIsExpanded] = useState(true);

    const activeFilters = useMemo(() => {
      const { manager, legalEntity, department, competence } = filters;

      return !!(
        manager.values.length ||
        legalEntity.values.length ||
        department.length ||
        competence.length
      );
    }, [filters]);

    const newFilterChanges = useMemo(
      () => JSON.stringify(appliedFilters) !== JSON.stringify(filters),
      [appliedFilters, filters],
    );

    const updateForm = (f: Partial<IAssignDialogFormForm>) => {
      setForm((prev) => ({ ...prev, ...f }));
    };

    const updateFilters = (f: Partial<IAssignDialogFormFilters>) => {
      setFilters((prev) => ({ ...prev, ...f }));
    };

    const resetFilters = useCallback(() => {
      setFilters(parentFilters);
      resetOnClick?.();
    }, [parentFilters, resetOnClick]);

    useImperativeHandle(
      ref,
      () => ({
        getFilterValues: () => filters,
      }),
      [filters],
    );

    useEffect(() => {
      setFilters(parentFilters);
      setAppliedFilters(parentFilters);
    }, [parentFilters]);

    return (
      <Stack data-automation-id="AssignDialogForm" gap={2}>
        <AssignDialogSearch form={form} setForm={updateForm} />
        <Grid container columnSpacing={5.75} data-automation-id="FormGrid" rowSpacing={2}>
          <Grid item xs={6}>
            <Grid container columnSpacing={1}>
              <Grid item md={6} xs={12}>
                <TextField
                  ariaLabel={t('AssignDialogForm.HoursLabel')}
                  data-automation-id="HoursInput"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {form.hours && (
                          <IconButton
                            title={t('ClearSearchButtonText')}
                            onClick={() => updateForm({ hours: '' })}
                            size="small"
                          >
                            <Clear fontSize="small" />
                          </IconButton>
                        )}
                        <Search fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  label={t('AssignDialogForm.HoursLabel')}
                  onChange={(e) => updateForm({ hours: e.target.value })}
                  size="small"
                  value={form.hours}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" justifyContent="flex-end">
              <ToggleButtonGroup
                aria-label={t('AssignDialogForm.ExpandCollapseButton.Label')}
                data-automation-id="ExpandCollapseButtonGroup"
                exclusive
                onChange={() => setFiltersIsExpanded(!filtersIsExpanded)}
                value={filtersIsExpanded}
              >
                <ToggleButton
                  className={styles.filterToggleButton}
                  data-automation-id="ExpandCollapseButton"
                  size="small"
                  tooltipProps={
                    filtersIsExpanded
                      ? {
                          title: t('AssignDialogForm.ExpandCollapseButton.HideFilters'),
                          placement: 'top',
                        }
                      : {
                          title: t('AssignDialogForm.ExpandCollapseButton.ShowFilters'),
                          placement: 'top',
                        }
                  }
                  value
                >
                  <Badge
                    color="primary"
                    data-automation-id="AssignDialogFormFilterBadge"
                    variant={activeFilters ? 'dot' : undefined}
                  >
                    <FilterList />
                  </Badge>
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Grid>

          {filtersIsExpanded && (
            <>
              <Grid item xs={6}>
                <AutocompleteWithRadios
                  data-automation-id="Manager"
                  filterId="project-manager"
                  placeholder={t('AssignDialogForm.ManagerLabel')}
                  value={filters.manager}
                  setValue={(value) => updateFilters({ manager: value })}
                />
              </Grid>
              <Grid item xs={6}>
                <AutocompleteWithRadios
                  data-automation-id="MultiLegalEntity"
                  filterId="legal-entity-employee"
                  placeholder={t('AssignDialogForm.MultiLegalEntityLabel')}
                  value={filters.legalEntity}
                  setValue={(value) => updateFilters({ legalEntity: value })}
                />
              </Grid>
              <Grid item xs={6}>
                <FilterAutocomplete
                  data-automation-id="DepartmentFilterAutocomplete"
                  disableCloseOnSelect
                  filterId="project-department"
                  inputStyles={{ height: 23 }}
                  isOptionEqualToValue={(o, v) => o.value === v.value}
                  label={t('AssignDialogForm.DepartmentLabel')}
                  multiple
                  onChange={(_, v) =>
                    updateFilters({ department: v as IAutocompleteWithRadiosOption[] })
                  }
                  placeholder={t('AssignDialogForm.DepartmentLabel')}
                  size="small"
                  value={filters.department}
                />
              </Grid>
              <Grid item xs={6}>
                <CompetencePicker
                  competence={filters.competence}
                  data-automation-id="CompetencePicker"
                  setCompetence={(v) => updateFilters({ competence: v })}
                />
              </Grid>
              {(resetOnClick || applyOnClick) && (
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="flex-end">
                    {resetOnClick && (
                      <Button
                        data-automation-id="ResetFiltersButton"
                        disabled={!newFilterChanges && !activeFilters}
                        onClick={resetFilters}
                        variant="outlined"
                      >
                        {t('AssignDialogForm.ResetFilters')}
                      </Button>
                    )}
                    {applyOnClick && (
                      <Button
                        data-automation-id="ApplyFiltersButton"
                        disabled={!newFilterChanges}
                        onClick={applyOnClick}
                        variant="contained"
                      >
                        {t('AssignDialogForm.ApplyFilters')}
                      </Button>
                    )}
                  </Stack>
                </Grid>
              )}
            </>
          )}
        </Grid>
        {showDivider && <Divider />}
      </Stack>
    );
  },
);
