import { MoreVert } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { ConfirmOptions } from 'material-ui-confirm';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Menu, Typography } from 'src/components/mui-components';
import { useMenu } from 'src/hooks/useMenu';
import { AssignFlowStore, IAssignFlowValue } from 'src/stores/ResourcePlannerStore/AssignFlowStore';
import { AssignProjectToResourceDialog } from './components/AssignProjectToResourceDialog';
import { AssignResourceToProjectDialog } from './components/AssignResourceToProjectDialog';
import { AssignTaskToResourcesDialog } from './components/AssignTaskToResourcesDialog';
import { RemoveTaskOrResourceDialog } from './components/RemoveTaskOrResourceDialog';

interface IAssignFlow {
  canAssign: boolean;
  canDelete: boolean;
  isProject: boolean;
  isWorkItem: boolean;
  isTask: boolean;
  value: IAssignFlowValue;
}

export const AssignFlow = ({
  canAssign,
  canDelete,
  isProject,
  isTask,
  isWorkItem,
  value,
}: IAssignFlow) => {
  const { t } = useTranslation('assignFlow');

  const { anchorEl, menuIsOpen, menuOnClose, setAnchorEl } = useMenu();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const confirmOptions: ConfirmOptions = {
    title: t('ConfirmDialog.Title'),
    description: t('ConfirmDialog.Description'),
    dialogProps: {
      maxWidth: 'xs',
    },
    confirmationText: t('ConfirmDialog.ConfirmationText'),
    confirmationButtonProps: {
      size: 'small',
      variant: 'contained',
    },
    cancellationText: t('ConfirmDialog.CancellationText'),
    cancellationButtonProps: {
      size: 'small',
      variant: 'outlined',
    },
  };

  const itemId = isWorkItem ? value.workItemSourceReferenceId : value.resourceSourceReferenceId;

  const menuItems = useMemo(() => {
    if (canDelete) {
      return [
        {
          id: 'remove',
          label: isWorkItem ? t('RemoveTask') : t('RemoveAllocation'),
          action: () => setDialogIsOpen(true),
        },
      ];
    }

    if (isTask) {
      return [
        {
          id: 'assign',
          label: isWorkItem ? t('AssignToEmployees') : t('AssignToProjects'),
          action: () => setDialogIsOpen(true),
        },
      ];
    }

    // isEmployee, isProject
    return [
      {
        id: 'assign',
        label: isWorkItem ? t('AssignToEmployee') : t('AssignToProject'),
        action: () => setDialogIsOpen(true),
      },
    ];
  }, [canDelete, isTask, isWorkItem, t]);

  const dialog = useMemo(() => {
    const dialogProps = { dialogIsOpen, setDialogIsOpen };

    if (canDelete) {
      return <RemoveTaskOrResourceDialog {...dialogProps} />;
    }

    if (isTask) {
      return <AssignTaskToResourcesDialog {...dialogProps} />;
    }

    return isWorkItem ? (
      <AssignProjectToResourceDialog {...dialogProps} />
    ) : (
      <AssignResourceToProjectDialog {...dialogProps} />
    );
  }, [canDelete, dialogIsOpen, isTask, isWorkItem]);

  return (
    <AssignFlowStore value={{ ...value, confirmOptions }}>
      {/* `isProject` condition is needed since RP returns `canAssign: false` */}
      {(canAssign || canDelete || isProject) && (
        <>
          <IconButton
            aria-controls={`ActionsMenu-${itemId}`}
            aria-expanded={!!menuIsOpen}
            aria-haspopup="true"
            data-automation-id={`ActionsIconButton-${itemId}`}
            id={`ActionsIconButton-${itemId}`}
            onClick={(e) => setAnchorEl(e.currentTarget)}
            size="small"
            title={t('ActionsMenuButtonText')}
          >
            <MoreVert fontSize="small" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id={`ActionsMenu-${itemId}`}
            MenuListProps={{ 'aria-labelledby': `ActionsIconButton-${itemId}` }}
            open={menuIsOpen}
            onClose={() => menuOnClose()}
          >
            {menuItems.map((item) => (
              <MenuItem
                data-automation-id={`MenuItem-${item.id}`}
                key={item.id}
                onClick={() => menuOnClose(() => item.action())}
              >
                <Typography fontSize={14}>{item.label}</Typography>
              </MenuItem>
            ))}
          </Menu>
          {dialog}
        </>
      )}
    </AssignFlowStore>
  );
};
