import {
  ListItem,
  ListItemText,
  toggleButtonClasses,
  toggleButtonGroupClasses,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetDropdownWithSearchAPI } from 'src/apis/dropdownAPI/get';
import chip from 'src/assets/styles/variables/colors/states/chip.module.scss';
import {
  Autocomplete,
  Checkbox,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from 'src/components/mui-components';
import { HighlightMatchingText } from 'src/components/utils/HighlightMatchingText';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { DataAutomationId } from 'src/utils/ts-utils';
import { ICompetencePickerOption } from './CompetencePicker.types';

interface ICompetencePicker extends DataAutomationId {
  competence: ICompetencePickerOption[];
  setCompetence: (c: ICompetencePickerOption[]) => void;
}

export const CompetencePicker = ({ competence, setCompetence, ...rest }: ICompetencePicker) => {
  const { t } = useTranslation('assignFlow');
  const [open, setOpen] = useState(false);

  const contentUrl = 'api/v2/filters/competence/content';
  const pageIdentifier = useGetCurrentPageIdentifier();
  const { dropdownList: d, isFetching } = useGetDropdownWithSearchAPI({
    key: 'filterInputcompetence',
    MSWKey: 'FILTER_INPUT_competence',
    path: `/${contentUrl}?pageIdentifier=${pageIdentifier}`,
    searchText: '',
    enabled: open,
  });
  const dropdownList = d.map((item) => ({
    ...item,
    levels: [],
  }));

  const competenceLevel = Array.from({ length: 5 }, (_, i) => i + 1);

  const getOptionLabel = (o: ICompetencePickerOption) => {
    if (!o.levels.length) {
      return o.label;
    }

    const sortedLevels = o.levels.sort((a, b) => a - b);
    return `${o.label} - ${sortedLevels.toString()}`;
  };

  const getCompetence = (option: ICompetencePickerOption) =>
    competence.find((c) => c.value === option.value);

  /**
   * The requirement is to select all competence levels equal to or greater than the selected level.
   *
   * e.g. Selecting level `3` selects `3`, `4`, and `5`.
   */
  const toggleButtonGroupOnChange = (o: ICompetencePickerOption, v: number[]) => {
    const selection = getCompetence(o);
    if (!selection?.value) {
      return;
    }

    const competenceList = [...competence];
    const competenceIndex = competenceList.findIndex((c) => c.value === selection.value);
    const existingLevels = competenceList[competenceIndex].levels;

    /**
     * MUI's `v` always returns all the selected levels in an array.
     *
     * To get the selected level, find the difference between `existingLevels` and `v` (set theory).
     *
     * Ascending (selecting a higher level) and descending (selecting a lower level) requires
     * different filtering methods to achieve proper difference.
     */
    const differenceAscending = v.filter((item) => !existingLevels.includes(item));
    const differenceDescending = existingLevels.filter((item) => !v.includes(item));

    /**
     * Only one of the above arrays will contain a number.
     */
    const selectedLevel = differenceAscending[0] ?? differenceDescending[0];

    /**
     * Generate levels equal to or greater than the selected level.
     *
     * e.g. `selectedLevel = 3` outputs `[3, 4, 5]`.
     */
    const levels = Array.from({ length: 5 - selectedLevel + 1 }, (_, i) => i + selectedLevel);

    competenceList[competenceIndex] = { ...selection, levels };
    setCompetence(competenceList);
  };

  return (
    <Autocomplete
      data-automation-id={`${rest['data-automation-id']}Autocomplete`}
      disableCloseOnSelect
      getOptionLabel={(option) => getOptionLabel(option as ICompetencePickerOption)}
      inputStyles={{ height: 23 }}
      isOptionEqualToValue={(o, v) => o.value === v.value}
      label={t('CompetencePicker.Label')}
      loading={isFetching}
      multiple
      onChange={(_, v) => setCompetence(v as ICompetencePickerOption[])}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      options={dropdownList}
      placeholder={t('CompetencePicker.Placeholder')}
      renderOption={(props, option, state) => (
        <ListItem {...props}>
          <Stack alignItems="center" direction="row" flex={1}>
            <Checkbox
              checked={state.selected}
              data-automation-id={`${rest['data-automation-id']}Checkbox`}
            />
            <Stack
              alignItems="center"
              direction="row"
              flex={1}
              justifyContent="space-between"
              marginBlock={1}
            >
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                <HighlightMatchingText matchName={state.inputValue} name={option.label} />
              </ListItemText>
              {state.selected && (
                <ToggleButtonGroup
                  aria-label={t('CompetencePicker.ToggleButtonGroupLabel', {
                    optionLabel: option.label,
                  })}
                  onChange={(_, v) => toggleButtonGroupOnChange(option, v)}
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    display: 'flex',
                    [`& .${toggleButtonGroupClasses.grouped}, & .${toggleButtonGroupClasses.grouped}:not(:first-of-type)`]:
                      {
                        border: `2px solid ${chip.colorChipPrimary}`,
                        borderRadius: 0,
                        height: 18,
                        margin: '3px',
                        padding: 0,
                        width: 18,
                      },
                    [`& .${toggleButtonClasses.root}:hover`]: {
                      backgroundColor: chip.colorChipPrimary,
                    },
                    [`& .${toggleButtonGroupClasses.grouped}.${toggleButtonClasses.selected}`]: {
                      backgroundColor: chip.colorChipPrimary,
                    },
                    [`& .${toggleButtonGroupClasses.grouped}.${toggleButtonClasses.selected}+.${toggleButtonGroupClasses.grouped}.${toggleButtonClasses.selected}`]:
                      {
                        margin: '3px',
                      },
                    [`& .${toggleButtonClasses.selected}:hover`]: {
                      backgroundColor: chip.colorChipPrimary,
                      borderColor: chip.colorChipPrimary,
                    },
                  }}
                  tabIndex={0}
                  value={getCompetence(option)?.levels}
                >
                  {competenceLevel.map((b) => (
                    <ToggleButton
                      key={b}
                      aria-label={String(b)}
                      data-automation-id={`${rest['data-automation-id']}ToggleButton`}
                      tooltipProps={{ title: null }}
                      value={b}
                    />
                  ))}
                </ToggleButtonGroup>
              )}
            </Stack>
          </Stack>
        </ListItem>
      )}
      size="small"
      value={competence}
    />
  );
};
