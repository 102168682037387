import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import {
  TextFieldProps as MuiTextFieldProps,
  TextField as TextFieldMui,
  TooltipProps,
  styled,
  tooltipClasses,
} from '@mui/material';
import { Ref, forwardRef } from 'react';
import { DataAutomationId } from 'src/utils/ts-utils';
import { Stack } from '../Stack';
import { Tooltip } from '../Tooltip';
import styles from './TextField.module.scss';

type TTooltipStatus = 'info' | 'error' | 'success' | 'warning';

type TextFieldProps = MuiTextFieldProps & {
  usePopOutValidation?: boolean;
  errorMessage?: string;
  status?: TTooltipStatus;
  ariaLabel: string;
} & DataAutomationId &
  (WithLabelProps | WithoutLabelProps);

type WithLabelProps = {
  label: string;
};

type WithoutLabelProps = {
  hiddenLabel?: boolean;
  ariaLabel: string;
};

const statusIconMap: Record<TTooltipStatus, JSX.Element> = {
  info: <InfoOutlinedIcon fontSize="small" />,
  error: <ErrorOutlineOutlinedIcon fontSize="small" />,
  success: <CheckCircleOutlinedIcon fontSize="small" />,
  warning: <WarningAmberOutlinedIcon fontSize="small" />,
};

interface IStyledTooltip extends TooltipProps {
  status: TTooltipStatus;
}

const StyledTooltip = styled(({ className, ...props }: IStyledTooltip) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, ...props }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette[props.status].main}`,
    boxShadow: theme.shadows[1],
    color: theme.palette[props.status].main,
    fontSize: 11,
    maxWidth: 150,
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette[props.status].main}`,
  },
}));

export const TextField = forwardRef(
  (
    { usePopOutValidation, errorMessage, status, ariaLabel, ...rest }: TextFieldProps,
    ref: Ref<HTMLDivElement>,
  ) => (
    <StyledTooltip
      arrow
      open={!!errorMessage}
      slotProps={{
        popper: { modifiers: [{ name: 'offset', options: { offset: [0, -9] } }] },
      }}
      title={
        usePopOutValidation && status && errorMessage ? (
          <Stack className={styles.helperTooltip}>
            {statusIconMap[status]}
            {errorMessage}
          </Stack>
        ) : null
      }
      status={status ?? 'info'}
    >
      <TextFieldMui
        ref={ref}
        {...rest}
        inputProps={{
          ...(rest.inputProps ?? {}),
          'aria-label': ariaLabel,
          'data-automation-id': `${rest['data-automation-id']}Input`,
        }}
      />
    </StyledTooltip>
  ),
);
