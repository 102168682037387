const availableHoursEntities = [
  {
    Properties: {
      UserId: 1,
      FullName: 'Anders Nielsen',
      Position: 'Projektleder',
      LegalEntity: 'TimeLog Automatic Dem',
      Department: 'Produktion',
      Capacity: {
        AvailableHours: 123.3,
        TotalHours: 123.3,
      },
    },
  },
  {
    Properties: {
      UserId: 6,
      FullName: 'Carl Chan',
      Position: 'Leder',
      LegalEntity: 'TimeLog Automatic Dem',
      Department: 'Ledelse',
      Capacity: {
        AvailableHours: 123.3,
        TotalHours: 123.3,
      },
    },
  },
  {
    Properties: {
      UserId: 11,
      FullName: 'Gerda Angelsen',
      Position: 'Ekstern konsulent',
      LegalEntity: 'TimeLog Automatic Dem',
      Department: 'Administration',
      Capacity: {
        AvailableHours: 58.0,
        TotalHours: 58.0,
      },
    },
  },
  {
    Properties: {
      UserId: 13,
      FullName: 'Test TCC Past',
      Position: 'QA',
      LegalEntity: 'TimeLog Automatic Dem',
      Department: 'Ledelse',
      Capacity: {
        AvailableHours: 123.3,
        TotalHours: 123.3,
      },
    },
  },
  {
    Properties: {
      UserId: 14,
      FullName: 'James  Bong',
      Position: '',
      LegalEntity: 'TimeLog Automatic Dem',
      Department: 'Administration',
      Capacity: {
        AvailableHours: 123.3,
        TotalHours: 123.3,
      },
    },
  },
  {
    Properties: {
      UserId: 50,
      FullName: '1 Alam  Tam Future',
      Position: 'Developer ',
      LegalEntity: 'TimeLog Automatic Dem',
      Department: 'R&D ',
      Capacity: {
        AvailableHours: 123.3,
        TotalHours: 123.3,
      },
    },
  },
  {
    Properties: {
      UserId: 53,
      FullName: 'Linda Lim',
      Position: '',
      LegalEntity: 'TimeLog Automatic Dem',
      Department: 'Administration',
      Capacity: {
        AvailableHours: 123.3,
        TotalHours: 123.3,
      },
    },
  },
  {
    Properties: {
      UserId: 54,
      FullName: 'Amy Aw',
      Position: '',
      LegalEntity: 'TimeLog Automatic Dem',
      Department: 'Administration',
      Capacity: {
        AvailableHours: 58.0,
        TotalHours: 58.0,
      },
    },
  },
  {
    Properties: {
      UserId: 70,
      FullName: '1 Daniel Yap',
      Position: '',
      LegalEntity: 'TimeLog Automatic Dem',
      Department: 'Administration',
      Capacity: {
        AvailableHours: 123.3,
        TotalHours: 123.3,
      },
    },
  },
  {
    Properties: {
      UserId: 72,
      FullName: 'Belinda (A) Bong',
      Position: '',
      LegalEntity: 'TimeLog Automatic Dem',
      Department: 'Administration',
      Capacity: {
        AvailableHours: 123.3,
        TotalHours: 123.3,
      },
    },
  },
];

const addMultiplierToEntities = () =>
  availableHoursEntities.map((e, i) => ({
    ...e,
    Properties: {
      ...e.Properties,
      Capacity: {
        ...e.Properties.Capacity,
        AvailableHours: ((7 - i) / 7) * 100,
        TotalHours: 100.0,
      },
    },
  }));

export default {
  Entities: addMultiplierToEntities(),
  Links: [
    {
      Href: 'https://uat.timelog.com/rp-test1/api/v2/resource-planner/employee-search',
      Rel: 'self',
    },
  ],
};
