import { Box, Stack, Tooltip } from '@mui/material';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Typography } from 'src/components/mui-components';
import { TCompetence } from '../AssignDialogForm/components/CompetencePicker';
import styles from './CompetenceList.module.scss';

interface ICompetenceList {
  competenceList: TCompetence;
  noOfCompetenceToDisplay?: number;
}

export const CompetenceList = ({
  competenceList = [],
  noOfCompetenceToDisplay = 3,
}: ICompetenceList) => {
  const { t } = useTranslation('assignFlow');

  const sortedList = competenceList.sort((a, b) => b.value - a.value);
  const list = noOfCompetenceToDisplay ? sortedList.slice(0, noOfCompetenceToDisplay) : sortedList;
  const competenceScore = Array.from({ length: 5 }, (_, i) => i + 1);

  return (
    <Stack gap={0} width="100%">
      {list.length ? (
        list.map((c) => (
          <Tooltip
            disableInteractive
            key={c.name}
            placement="bottom-start"
            slotProps={{
              popper: { modifiers: [{ name: 'offset', options: { offset: [6, -12] } }] },
            }}
            title={`${c.name} (${c.value})`}
          >
            <Stack direction="row" justifyContent="space-between" mx={-2} px={2} py={1 / 4}>
              <Typography fontSize={12} noWrap>
                {c.name}
              </Typography>
              <Stack alignItems="center" direction="row" gap={0.5}>
                {competenceScore.map((s) => (
                  <Box
                    key={`filled-${s}`}
                    className={cx(styles.box, s <= c.value ? styles.activeBox : '')}
                  />
                ))}
              </Stack>
            </Stack>
          </Tooltip>
        ))
      ) : (
        <Typography fontSize={12} noWrap>
          {t('CompetenceList.NoCompetence')}
        </Typography>
      )}
    </Stack>
  );
};
