import { Stack, Typography } from 'src/components/mui-components';

interface INoRowsOverlay {
  message: string;
}

export const NoRowsOverlay = ({ message }: INoRowsOverlay) => (
  <Stack alignItems="center" height="100%" justifyContent="center" width="100%">
    <Typography>{message}</Typography>
  </Stack>
);
