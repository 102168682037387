import { Card, CardContent, cardContentClasses } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const PleaseSearch = () => {
  const { t } = useTranslation('assignFlow');

  return (
    <Card
      data-automation-id="PleaseSearch"
      sx={{
        width: '100%',
        [`& .${cardContentClasses.root}:last-child`]: {
          paddingBlockEnd: 2,
        },
      }}
      variant="outlined"
    >
      <CardContent>{t('PleaseSearch')}</CardContent>
    </Card>
  );
};
