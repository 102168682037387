import { DialogActions } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDeleteWorkItemRelationship } from 'src/apis/resourcePlannerAPI';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  ToastifyAlert,
  Typography,
} from 'src/components/mui-components';
import { useAssignFlowStore } from 'src/stores/ResourcePlannerStore/AssignFlowStore';

interface IRemoveTaskOrResourceDialog {
  dialogIsOpen: boolean;
  setDialogIsOpen: (isOpen: boolean) => void;
}

export const RemoveTaskOrResourceDialog = ({
  dialogIsOpen,
  setDialogIsOpen,
}: IRemoveTaskOrResourceDialog) => {
  const { t } = useTranslation('assignFlow');
  const {
    isWorkItem,
    name,
    parentTask,
    resourceId,
    resourceSourceReferenceId,
    resourceType,
    workItemId,
    workItemSourceReferenceId,
    workItemType,
  } = useAssignFlowStore();

  const dialogOnClose = () => {
    setDialogIsOpen(false);
  };

  const { mutateAsync: deleteWorkItemRelationship } = useDeleteWorkItemRelationship();

  const onDelete = () => {
    setDialogIsOpen(false);
    toast.promise(
      deleteWorkItemRelationship({
        resourceId,
        resourceSourceReferenceId,
        resourceType,
        workItemId,
        workItemSourceReferenceId,
        workItemType,
      }),
      {
        pending: {
          render: (
            <ToastifyAlert
              customIcon={<CircularProgress size={20} />}
              description={
                <Trans
                  i18nKey="Toast.RemovingItem"
                  key="Toast.RemovingItem"
                  defaults={t('Toast.RemovingItem')}
                  values={{ x: name, y: parentTask?.name }}
                  components={[
                    <span key={0} style={{ fontWeight: 500 }}>
                      {name}
                    </span>,
                    <span key={1} style={{ fontWeight: 500 }}>
                      {parentTask?.name}
                    </span>,
                  ]}
                />
              }
            />
          ),
        },
        success: {
          render: (
            <ToastifyAlert
              title={t('Toast.SuccessTitle')}
              description={
                <Trans
                  i18nKey="Toast.RemoveItemSuccess"
                  key="Toast.RemoveItemSuccess"
                  defaults={t('Toast.RemoveItemSuccess')}
                  values={{ x: name, y: parentTask?.name }}
                  components={[
                    <span key={0} style={{ fontWeight: 500 }}>
                      {name}
                    </span>,
                    <span key={1} style={{ fontWeight: 500 }}>
                      {parentTask?.name}
                    </span>,
                  ]}
                />
              }
            />
          ),
        },
      },
      {
        autoClose: 5000,
        closeButton: false,
      },
    );
  };

  return (
    <Dialog
      data-automation-id="RemoveTaskOrResourceDialog"
      maxWidth="xs"
      open={dialogIsOpen}
      onClose={dialogOnClose}
    >
      <DialogTitle data-automation-id="RemoveTaskOrResourceDialogTitle">
        <Trans
          i18nKey="RemoveXfromY"
          key="RemoveXfromY"
          defaults={t('RemoveXfromY')}
          values={{ x: name, y: parentTask?.name }}
          components={[<b key={0}>{name}</b>, <b key={1}>{parentTask?.name}</b>]}
        />
      </DialogTitle>
      <DialogContent>
        <Typography>
          {isWorkItem ? t('ConfirmRemoveTask') : t('ConfirmRemoveAllocation')}.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button data-automation-id="CancelButton" onClick={dialogOnClose} variant="outlined">
          {t('Cancel')}
        </Button>
        <Button data-automation-id="YesButton" onClick={onDelete} variant="contained">
          {t('Yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
