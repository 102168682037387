import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { getPathWithQueries } from 'src/utils/url/url';
import { THourlyRatesRes } from './getHourlyRatesAPI.types';

const GET_HOURLY_RATES_URL_PATH = '/api/v2/contract-hourly-rates/dropdown-content';
const GET_HOURLY_RATES_KEY = 'HourlyRates';

interface IGetHourlyRates {
  taskId?: string;
}

const getHourlyRates = ({ taskId }: IGetHourlyRates): Promise<THourlyRatesRes> =>
  getFetch({
    path: getPathWithQueries(GET_HOURLY_RATES_URL_PATH, {
      taskId,
    }),
    key: GET_HOURLY_RATES_KEY,
  });

export const useGetHourlyRates = (params: IGetHourlyRates, enableQuery?: boolean) => {
  const { data: { entities } = {}, ...rest } = useQuery(
    [GET_HOURLY_RATES_KEY, params.taskId],
    () => getHourlyRates(params),
    {
      enabled: enableQuery && !!params.taskId,
    },
  );

  return { data: entities, ...rest };
};
