import { Button, Stack } from '@mui/material';
import { Typography } from 'src/components/mui-components';
import { Trans, useTranslation } from 'react-i18next';
import { useIsValidIndexControlName } from 'src/apis/indexAPI';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { Link } from 'react-router-dom';
import { OpenInNew } from '@mui/icons-material';

export const NoCompetencesAdded = () => {
  const { t } = useTranslation('employeeCompetences');
  const { isError, isValidIndexControlName: hasAccessToAddCompetences } =
    useIsValidIndexControlName('Competences', 'CreateCompetencesV2');
  return (
    <Stack gap={2}>
      <Typography>{t('modals.AddCompetenceNoDataText')}</Typography>
      <ResponseHandler isError={isError}>
        {hasAccessToAddCompetences ? (
          <Trans
            i18nKey="modals.AddCompetenceNoDataCanCreateText"
            ns="employeeCompetences"
            components={[
              <Button
                endIcon={<OpenInNew />}
                component={Link}
                variant="outlined"
                to="/system-administration/employees/competences"
                target="_blank"
              />,
            ]}
          />
        ) : (
          <Typography>{t('modals.AddCompetenceNoDataCanNotCreateText')}</Typography>
        )}
      </ResponseHandler>
    </Stack>
  );
};
