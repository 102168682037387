import { GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { DatePicker } from 'src/components/mui-components';
import { useCustomEditComponent } from 'src/screens/ResourcePlanner/hooks';
import { getLanguageFromSiteLocale, localeDateFormat } from 'src/utils/date';
import { ComponentWrapper } from '../EditableComponents';
import { TDate } from '../EditableComponents.types';

interface IEditableStartDate extends GridRenderEditCellParams {
  ariaLabel: string;
  dataAutomationId: string;
  maxDate: TDate;
  minDate: TDate;
  textFieldId: string;
}

export const EditableStartDate = ({
  ariaLabel,
  dataAutomationId,
  field,
  id,
  maxDate,
  minDate,
  textFieldId,
  value,
}: IEditableStartDate) => {
  const siteLocale = useGetLocale();
  const siteLanguage = getLanguageFromSiteLocale(siteLocale);
  const { handleChange } = useCustomEditComponent<TDate>({ field, id });

  return (
    <ComponentWrapper>
      <DatePicker
        data-automation-id={`${id}${dataAutomationId}`}
        disablePast
        format={localeDateFormat(siteLanguage)}
        maxDate={maxDate ?? undefined}
        minDate={minDate ?? new Date()}
        onChange={handleChange}
        value={value}
        slotProps={{
          textField: { size: 'small', id: textFieldId, inputProps: { 'aria-label': ariaLabel } },
        }}
      />
    </ComponentWrapper>
  );
};
