import { Dispatch } from 'react';
import { HighlightMatchingText } from 'src/components/utils/HighlightMatchingText';
import { IAddCompetenceItems } from 'src/apis/employeeCompetencesAPI/types';

import { Box, Checkbox, ListItemButton, ListItemIcon, ListItemText, Rating } from '@mui/material';

export const AddCompetenceItem = ({
  id,
  isChecked,
  labelId,
  name,
  debouncedQuery,
  initialLevel,
  handleToggleCompetences,
  setCheckedCompetence,
  checkedCompetences,
}: {
  id?: number;
  isChecked: boolean;
  labelId: string;
  name: string;
  debouncedQuery: string;
  initialLevel: number | null;
  handleToggleCompetences: (value: number) => () => void;
  setCheckedCompetence: Dispatch<React.SetStateAction<IAddCompetenceItems[]>>;
  checkedCompetences: IAddCompetenceItems[];
}) => {
  const thisCompetence = checkedCompetences.find((c) => c.competenceId === id);
  const thisLevel = thisCompetence?.value ?? initialLevel;

  return (
    <Box sx={{ position: 'relative' }}>
      <ListItemButton
        role="button"
        onClick={handleToggleCompetences(id ?? 0)}
        dense
        sx={{
          pr: isChecked ? '130px' : undefined,
          '&[aria-selected="true"]': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
        }}
        data-automation-id={`AddCompetenceDialogItem${id}`}
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={isChecked}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <ListItemText
          id={labelId}
          primary={<HighlightMatchingText name={name} matchName={debouncedQuery} />}
        />
      </ListItemButton>
      {isChecked ? (
        <Rating
          sx={{
            position: 'absolute',
            right: 0,
            mr: 2,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
          name={`rating-${id}-${id}`}
          value={thisLevel}
          getLabelText={() => `${name}, level: ${thisLevel}`}
          onChange={(event, newValue) => {
            if (newValue === null) return;
            setCheckedCompetence([
              ...checkedCompetences.filter((item) => item?.competenceId !== id),
              { competenceId: id, value: newValue },
            ]);
          }}
          data-automation-id={`AddCompetenceDialogItemRating${id}`}
        />
      ) : null}
    </Box>
  );
};
