import { Stack } from '@mui/material';
import { format } from 'date-fns';
import { useConfirm } from 'material-ui-confirm';
import { ElementRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  IBulkAssignReq,
  useGetHourlyRates,
  useGetUnallocatedEmployees,
  usePostBulkAssign,
  usePostEmployeeSearch,
} from 'src/apis/resourcePlannerAPI';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  ToastifyAlert,
  Typography,
} from 'src/components/mui-components';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { convertObjectValueToNumber } from 'src/screens/ResourcePlanner/helper/convertObjectValueToNumber';
import { useAssignFlowStore } from 'src/stores/ResourcePlannerStore/AssignFlowStore';
import { useResourcePlannerStore } from 'src/stores/ResourcePlannerStore/ResourcePlannerStore';
import { useDebounce } from 'use-debounce';
import {
  ASSIGN_DIALOG_FILTERS,
  ASSIGN_DIALOG_FORM,
  AssignDialogForm,
  IAssignDialogFormFilters,
  IAssignDialogFormForm,
} from '../AssignDialogForm';
import { EmployeeTable, TEmployee } from '../EmployeeTable';
import { PleaseSearch } from '../EmptyStates/PleaseSearch';
import styles from './AssignTaskToResourcesDialog.module.scss';

interface IAssignTaskToResourcesDialog {
  dialogIsOpen: boolean;
  setDialogIsOpen: (isOpen: boolean) => void;
}

export const AssignTaskToResourcesDialog = ({
  dialogIsOpen,
  setDialogIsOpen,
}: IAssignTaskToResourcesDialog) => {
  type TEmployeeTableHandle = ElementRef<typeof EmployeeTable>;
  const employeeTableRef = useRef<TEmployeeTableHandle>(null);

  type TAssignDialogFormHandle = ElementRef<typeof AssignDialogForm>;
  const assignDialogFormRef = useRef<TAssignDialogFormHandle>(null);

  const { t } = useTranslation('assignFlow');
  const confirm = useConfirm();
  const { dateEnd } = useResourcePlannerStore();
  const { confirmOptions, name, parentProject, workItemSourceReferenceId } = useAssignFlowStore();

  const [filters, setFilters] = useState<IAssignDialogFormFilters>(ASSIGN_DIALOG_FILTERS);
  const [form, setForm] = useState<IAssignDialogFormForm>({
    ...ASSIGN_DIALOG_FORM,
    endDate: dateEnd,
  });
  const [debouncedForm] = useDebounce(form, 500);

  const { competence, department, legalEntity, manager } = filters;
  const { endDate, hours, query, startDate } = debouncedForm;

  const enableSearch =
    !!competence?.length ||
    !!department?.length ||
    !!hours ||
    !!legalEntity?.values.length ||
    !!manager?.values.length ||
    !!query;

  const {
    data: unallocatedEmployees,
    isError: unallocatedEmployeesIsError,
    isFetching: unallocatedEmployeesIsFetching,
  } = useGetUnallocatedEmployees(
    { projectId: parentProject?.id, taskId: workItemSourceReferenceId },
    enableSearch,
  );

  const {
    data: employeeSearch,
    isError: employeesIsError,
    isFetching: employeesIsFetching,
  } = usePostEmployeeSearch(
    { competence, department, endDate, hours, legalEntity, manager, query, startDate },
    enableSearch,
  );

  const {
    data: hr,
    isError: hourlyRatesIsError,
    isFetching: hourlyRatesIsFetching,
  } = useGetHourlyRates({ taskId: workItemSourceReferenceId }, enableSearch);

  const data = useMemo(() => {
    if (!employeeSearch?.length || !unallocatedEmployees?.length) {
      return [];
    }

    // Use employeeSearch as base, filter out employees that are already allocated
    return employeeSearch.reduce((a: TEmployee[], { properties: b }) => {
      const unallocatedEmployee = unallocatedEmployees.find(
        ({ properties: u }) => u.userId === b.userId,
      );
      if (unallocatedEmployee) {
        a.push({
          ...b,
          ...unallocatedEmployee.properties,
        });
      }
      return a;
    }, []);
  }, [employeeSearch, unallocatedEmployees]);

  const hourlyRates = useMemo(() => hr?.map(({ properties }) => properties) ?? [], [hr]);

  const getSelectedResource = () => employeeTableRef.current?.getFormValues() ?? [];

  const cleanUp = useCallback(() => {
    setFilters(ASSIGN_DIALOG_FILTERS);
    setForm({
      ...ASSIGN_DIALOG_FORM,
      endDate: dateEnd,
    });
    setDialogIsOpen(false);
  }, [dateEnd, setDialogIsOpen]);

  const dialogOnClose = () => {
    if (getSelectedResource().length) {
      confirm(confirmOptions).then(cleanUp);
      return;
    }

    cleanUp();
  };

  const applyFilters = () => {
    const filterValues = assignDialogFormRef.current?.getFilterValues();
    setFilters((prev) => filterValues ?? prev);
  };

  const resetFilters = () => {
    setFilters(ASSIGN_DIALOG_FILTERS);
  };

  const bulkAssignOnSuccess = () => {
    cleanUp();
    toast.success(
      <ToastifyAlert
        title={t('Toast.SuccessTitle')}
        description={
          <Trans
            i18nKey="Toast.AssignTaskToEmployeeSuccess"
            key="Toast.AssignTaskToEmployeeSuccess"
            ns="assignFlow"
            defaults={t('Toast.AssignTaskToEmployeeSuccess')}
            count={getSelectedResource().length}
            values={{ task: name, count: getSelectedResource().length }}
            components={[
              <span key={0} style={{ fontWeight: 500 }} />,
              <span key={1} style={{ fontWeight: 500 }} />,
            ]}
          />
        }
      />,
      {
        autoClose: 5000,
        closeButton: false,
      },
    );
  };

  const { mutate: postBulkAssign, isLoading } = usePostBulkAssign(bulkAssignOnSuccess);

  const assignTaskToResources = () => {
    if (!getSelectedResource().length || !employeeTableRef.current?.getFormIsValid()) {
      return;
    }

    const assignReqBody: IBulkAssignReq[] = getSelectedResource().map((r) => ({
      allocationEndDate: format(r.endDate, 'yyyy-MM-dd'),
      allocationStartDate: format(r.startDate, 'yyyy-MM-dd'),
      ...convertObjectValueToNumber({
        budgetHours: r.hours ?? 0,
        hourlyRateId: r.hourlyRate || r.suggestedHourlyRateId,
        projectId: parentProject?.id ?? 0,
        resourceSourceReferenceId: r.userId,
        workItemSourceReferenceId,
      }),
    }));

    postBulkAssign(assignReqBody);
    resetFilters();
  };

  useEffect(() => {
    setForm((prev) => ({ ...prev, endDate: dateEnd }));
  }, [dateEnd]);

  useEffect(() => {
    applyFilters();
  }, [endDate, hours, query, startDate]);

  return (
    <Dialog
      data-automation-id="AssignTaskToResourceDialog"
      fullWidth
      maxWidth="xl"
      onClose={dialogOnClose}
      open={dialogIsOpen}
      PaperProps={{
        sx: {
          height: '100%',
        },
      }}
    >
      <DialogTitle component={Stack} data-automation-id="AssignTaskToResourcesDialogTitle">
        <Typography lineHeight={1.6} mb={1} variant="h2">
          <Trans
            i18nKey="AssignTaskToEmployeeHeader"
            key="AssignTaskToEmployeeHeader"
            defaults={t('AssignTaskToEmployeeHeader')}
            values={{ task: name }}
            components={[<strong key={0}>{name}</strong>]}
          />
        </Typography>
        <AssignDialogForm
          applyOnClick={applyFilters}
          filters={filters}
          form={form}
          ref={assignDialogFormRef}
          resetOnClick={resetFilters}
          setForm={setForm}
          showDivider
        />
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <ResponseHandler
          FreshComponent={<PleaseSearch />}
          isError={unallocatedEmployeesIsError || employeesIsError || hourlyRatesIsError}
          isFresh={!enableSearch}
        >
          <Box className={styles.tableContainer}>
            <EmployeeTable
              data={data}
              endDate={endDate}
              hourlyRates={hourlyRates}
              isFetching={
                employeesIsFetching || unallocatedEmployeesIsFetching || hourlyRatesIsFetching
              }
              query={query}
              ref={employeeTableRef}
              startDate={startDate}
            />
          </Box>
        </ResponseHandler>
      </DialogContent>
      <DialogActions className={styles.dialogActions}>
        <>
          <Button
            data-automation-id="CancelButton"
            disabled={isLoading}
            onClick={cleanUp}
            variant="outlined"
          >
            {t('Cancel')}
          </Button>
          <LoadingButton
            data-automation-id="AssignTaskToResourcesButton"
            isLoading={isLoading}
            onClick={assignTaskToResources}
            variant="contained"
          >
            {t('Assign')}
          </LoadingButton>
        </>
      </DialogActions>
    </Dialog>
  );
};
